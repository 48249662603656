import React from "react";

const Stepper = ({ steps }) => {
  return (
    <div className="mt-8" style={{ display: "flex", width: "70%", justifyContent: "center"}}>
      {steps.map((step, index) => (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              // // style={{
              // //   borderRadius: "25px",
              // //   backgroundColor: "red",
              // //   height: 40,
              // //   width: 40,
              // // }}
              className="sm:w-10 sm:h-10 h-6 w-6"
              src={step.imgSrc}
            ></img>
            <h3 className="text-center sm:w-32 w-24 font-medium sm:text-sm text-xs mt-2">
              {step.title}
            </h3>
          </div>
          {index !== steps.length - 1 ? (
            <div
              style={{
                border: "2px solid black",
                width: "100%",
                height: 2,
                marginTop: 20,
              }}
            ></div>
          ) : null}
        </>
      ))}
    </div>
  );
};

export default Stepper;
