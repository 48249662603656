import React from "react";
import confusedBoy from "../images/confusedBoy.svg";
import { Link } from "react-router-dom";

const FEATURES = [
  "Hands-on exploration and live project demonstrations.",
  "Discuss future trends and opportunities in each domain.",
  "Understand the basics and advanced concepts of each field.",
  "Gain insights into potential careers and job roles.",
  "Learn about industry trends and skill demand.",
  "Help students identify their interests and strengths.",
  "Provide guidance on course selection.",
  "Q&A sessions to address student queries and concerns.",
];

const Intro = () => {
  return (
    <>
      <div className="m-auto max-w-6xl p-2 pt-16 h-5/6" id="exploration">
        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left">
          <div className="lg:w-1/2 flex flex-col md:mx-4 lg:px-0 px-16 justify-center">
            <img
              alt="card img"
              className="rounded-t float-right "
              src={confusedBoy}
            />
          </div>
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8"
          >
            <h6 className="text-4xl  text-blue-900 font-fun font-bold text-primaryGreen">
              Confused ?
            </h6>
            <h3 className="text-4xl font-bold ">Take Our Exploration Class</h3>
            <div className="flex justify-center">
              <ol className="flex gap-1.5 flex-col my-4">
                {FEATURES.map((feature) => {
                  return (
                    <li className="gap-2 text-left mb-2 items-center">
                      <span>
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#1486fc"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M12 2c-.791 0-1.55.314-2.11.874l-.893.893a.985.985 0 0 1-.696.288H7.04A2.984 2.984 0 0 0 4.055 7.04v1.262a.986.986 0 0 1-.288.696l-.893.893a2.984 2.984 0 0 0 0 4.22l.893.893a.985.985 0 0 1 .288.696v1.262a2.984 2.984 0 0 0 2.984 2.984h1.262c.261 0 .512.104.696.288l.893.893a2.984 2.984 0 0 0 4.22 0l.893-.893a.985.985 0 0 1 .696-.288h1.262a2.984 2.984 0 0 0 2.984-2.984V15.7c0-.261.104-.512.288-.696l.893-.893a2.984 2.984 0 0 0 0-4.22l-.893-.893a.985.985 0 0 1-.288-.696V7.04a2.984 2.984 0 0 0-2.984-2.984h-1.262a.985.985 0 0 1-.696-.288l-.893-.893A2.984 2.984 0 0 0 12 2Zm3.683 7.73a1 1 0 1 0-1.414-1.413l-4.253 4.253-1.277-1.277a1 1 0 0 0-1.415 1.414l1.985 1.984a1 1 0 0 0 1.414 0l4.96-4.96Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                      <h3 className="font-medium">{feature}</h3>
                    </li>
                  );
                })}
              </ol>
            </div>

            <div>
              <Link
                to="/exploration-class"
                className="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-primaryGreen border-2 border-transparent rounded-xl shadow-sm hover:bg-transparent hover:text-primaryGreen hover:border-primaryGreen focus:outline-none"
              >
                Book Exploration Class
                <svg
                  className="w-4 h-4 ml-1 group-hover: translate-x-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
