import React from "react";
import rocket from "./../images/rocket-svg.svg";
import group from "./../images/group.svg";

const AboutUs = () => {
  return (
    <section id="aboutus">
      <div className="m-auto max-w-6xl p-2 md:p-12 md:pt-0 h-5/6">
        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left items-start gap-4">
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <div className="text-blue-900 mb-4 flex lg:justify-start justify-center">
              <img src={group} width="72px" height="72px" alt="group"></img>
            </div>
            <h3 className="text-3xl font-bold text-center lg:text-left">
              Who{" "}
              <span className=" font-fun text-primaryGreen text-4xl">We</span>{" "}
              are ?
            </h3>
            <div>
              <p className="my-3  text-lg text-center lg:text-left lg:text-xl text-gray-600 font-medium px-2 lg:px-0">
                Step into the exciting world of Skill Crafters Community, where
                coding meets creativity and learning is an adventure! We're a
                passionate team of tech enthusiasts on a mission to inspire and
                educate kids through the magic of coding. Our dynamic programs,
                led by experienced instructors, provide a fun and supportive
                environment where young minds can explore, innovate, and unleash
                their full potential.
              </p>
            </div>
          </div>
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <div className="text-blue-900 mb-4 flex lg:justify-start justify-center">
              <img src={rocket} width="72px" height="72px" alt="rocket"></img>
            </div>
            <h3 className="text-3xl font-bold text-center lg:text-left">
              <span className="text-primaryGreen font-fun font-bold text-4xl">
                Our
              </span>{" "}
              Mission
            </h3>
            <div>
              <p className="my-3 text-lg text-center lg:text-left lg:text-xl text-gray-600 font-medium px-2 lg:px-0">
                Our mission is to equip children with the skills and knowledge
                they need to succeed in the digital age. We believe in a
                hands-on, project-based approach that makes learning to code an
                exciting adventure. By nurturing problem-solving abilities and
                encouraging innovation, we aim to inspire a lifelong love for
                technology and prepare our students for the future. Together, we
                can build a brighter tomorrow, one line of code at a time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
