import React from "react";
import JoinUs from "../components/JoinUs";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import CourseOverview from "../components/CourseOverview";
import Benefits from "../components/Benefits";
import AboutUs from "../components/AboutUs";

const Home = () => {
  return (
    <>
      <Hero />
      <Intro />
      <Benefits />
      <CourseOverview />
      <Faq />
      <AboutUs/>
      <JoinUs/>
      <Footer />
    </>
  );
};

export default Home;
