export const PYTHON_COURSES = [
  {
    title: "Python Foundations: Mastering Essentials",
    features: [
      "Introduction to Programming and Python",
      "Variables, & Data Types",
      "Operators & conditional statement",
      "Loops and Functions",
      "Data Structures",
      "OOPS",
      "Game Development",
    ],
    numOfClasses: "23",
    price: "20$",
    link: "https://drive.google.com/file/d/19r3HCL9RTsuWI0QeLpbrB0qhonW_nyHB/view?usp=drive_link",
  },
  {
    title: "Exploration in Data science with python",
    features: [
      " Data Structures and Algorithms",
      "Advanced Patterns and Logic Building",
      "Statistics and Probability",
      "Data Manipulation and Visualization",
      "Data Analysis Libraries",
    ],
    numOfClasses: "23",
    price: "20$",
    link: "https://drive.google.com/file/d/1fko7Chlbhfrsx8jxPY8-S9X5MOvJ0kO_/view?usp=drive_link",
  },
  {
    title: "Mastery in Machine learning and AI",
    features: [
      "Fundamentals of Python",
      "Machine Learning",
      "Scikit-learn",
      "Model Evaluation",
      "Lasso and Ridge Regression",
      "Model Optimization",
    ],
    numOfClasses: "23",
    price: "20$",
    link: "https://drive.google.com/file/d/1AdrB9iGYp1aChndZQs5Y8dj1oEsMBj9w/view?usp=drive_link",
  },
];

export const WEBDEV_COURSES = [
  {
    title: "Web Foundations: Mastering Essentials",
    features: [
      "HTML",
      "CSS",
      "JavaScript",
      "Building Responsive Websites",
      "Version Control",
      "Github and Deployment",
    ],
    numOfClasses: "23",
    price: "20$",
    link: "https://drive.google.com/file/d/1vNjkyOFbGunox8uFkHMz3PxOLvVbB0tW/view?usp=drive_link",
  },
  {
    title: "Exploring Advanced Web With ReactJs",
    features: [
      "Advanced Html & Css",
      "Modern JavaScript",
      "CSS and its Frameworks",
      "Problem Solving and Automation",
      "React",
      "APIs and Real-Time Data",
    ],
    numOfClasses: "23",
    price: "20$",
    link: "https://drive.google.com/file/d/1PiLwoQ30pOIKuxJgbihnPfvifgn6fP9S/view?usp=drive_link",
  },
  {
    title: "Backend: Building Powerful Web apps",
    features: [
      "Foundations of Web Development",
      "Node.js",
      "SQL Database Management",
      "Integrating Frontend and Backend",
      "Error Handling and Logging",
      "Version Control and Deployment",
    ],
    numOfClasses: "23",
    price: "20$",
    link: "https://drive.google.com/file/d/1y63GL4ivfUeHJCuThk2HmGjZtYdr95af/view?usp=drive_link",
  },
  {
    title: "App: Building Powerful Mobile apps",
    features: [
      "React Fundamentals and React Native Introduction",
      "React Native Components and Styling",
      "Navigation in React Native",
      "Handling User Input",
      "Data Fetching and API Integration",
      "Native Features and Device Integration",
    ],
    numOfClasses: "23",
    price: "20$",
    link: "https://drive.google.com/file/d/1MuJsXtsx5qs6tLUN1IzY1pP4JqM068DH/view?usp=drive_link",
  },
];

export const CYBER_SECURITY_COURSES = [
  {
    title: "Cyber Security: Protecting Ourselves",
    features: [
      "Fundamentals of Cybersecurity",
      "Online Safety and Privacy",
      "Recognizing and Mitigating Cyber Threats",
      "Data Privacy and Protection",
      "Cyber Etiquette and Legal Awareness",
      "Cybersecurity Tools and Coding",
    ],
    numOfClasses: "23",
    price: "20$",
    link: "https://drive.google.com/file/d/18HlgJQrIjYOpUtVpvw-nD41CAd-8JaKV/view?usp=drive_link",
  },
];

export const MATHS = [
  {
    title: "Maths (kindergarten to 8th grade)",
    features: [
      "Comprehensive Curriculum Coverage for grade KG to 8th",
      "Alignment with School Standards",
      "Focus on Conceptual Understanding",
      "Skill Development and Practice",
      "Enrichment Programs and Support",
    ],
    numOfClasses: "23",
    price: "20$",
    link: "/demo-class",
  },
];
