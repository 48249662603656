import Accordion from "./Accordion"

const Faq = () => {
    return (
      <div className="m-auto max-w-6xl p-2 h-5/6">
        <div className="my-4 py-4">
          <h2 className="font-fun my-2 text-center text-4xl font-bold text-primaryGreen">
            Got doubts?
          </h2>

          <h2 className="text-2xl text-center font-bold my-1">
            Frequently Asked Questions
          </h2>
        </div>
        <Accordion />
      </div>
    );
}

export default Faq