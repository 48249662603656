import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/Navbar/NavBar";
import heroImg from "../images/heroImg.svg";

const FEATURES = [
  "Learn from Tech Buddies",
  "Project-Based Learning",
  "Comprehensive Exploration of Technologies",
  "Customised Learning",
  "Lifetime courses access",
];

const Hero = () => {
  return (
    <>
      <div id="hero" className="hero">
        <div>
          <NavBar />
        </div>

        <div
          className="m-auto overflow-hidden mx-4 p-2 md:px-12 h-5/6"
          data-aos="zoom-in"
        >
          <div
            id="hero"
            className="flex flex-col lg:flex-row mt-10 pt-8 justify-between text-center lg:text-left"
          >
            <div
              className="lg:w-1/2 flex flex-col justify-center"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <h1 className="mb-5 md:text-6xl text-5xl font-sans font-semibold">
                Learning made{" "}
                <span className="fun font-fun font-extrabold">fun</span>
                <br></br> for young minds
              </h1>
              <div className="text-xl font-semibold tracking-tight mb-5 text-lightGrayText">
                Small Hands, Big Dreams: Turning Visions into Reality
              </div>
              <div className="flex lg:justify-start justify-center">
                <ol>
                  {FEATURES.map((feature) => {
                    return (
                      <li className="gap-2 items-center font-medium">
                        <span>
                          <svg
                            class="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#ffba00"
                            viewBox="0 0 24 24"
                          >
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                          </svg>
                        </span>
                        <h2 className="text-left">{feature}</h2>
                      </li>
                    );
                  })}
                </ol>
              </div>
              <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                <Link
                  to="/courses"
                  className="inline-flex mt-6 items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-primaryGreen border-2 border-transparent rounded-xl shadow-sm hover:bg-transparent hover:text-primaryGreen hover:border-primaryGreen focus:outline-none"
                >
                  View All Courses
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
            <div
              className="flex justify-center lg:justify-end w-full lg:w-1/2 "
              data-aos="fade-up"
              data-aos-delay="700"
            >
              <img
                
                alt="card img"
                className="rounded-t sm:h-128 sm:w-128 float-right duration-1000 sm:mt-0 w-full mt-4"
                src={heroImg}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
