import React, { useEffect, useState } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import DemoForm from "../components/DemoForm";
import {
  DEMO_CLASS_CARD_CONTENT,
  DEMO_PAGE,
  EXPLORATION_CLASS_CARD_CONTENT,
  EXPLORATION_PAGE,
} from "../components/content/DemoContent";
import { useLocation } from "react-router-dom";
// import emailjs from 'emailjs-com';

const DemoProduct = (props) => {
  const location = useLocation();
  useDocTitle("SkillCraftersCo - Take our Demo Class");
  useEffect(() => {
    if (!location) return;
    const { hash } = location;
    if (hash) return;
    window.scrollTo(0, 0);
  }, []);

  const url = window.location.href;
  const urlObject = new URL(url);
  const path = urlObject.pathname;
  const pageType = path
    .split("/")
    .filter((segment) => segment)
    .pop();

  const [activeTab, setActiveTab] = useState(pageType);

  function sendEmail(e) {
    e.preventDefault();

    // emailjs.sendForm('service_7uy4ojg', 'template_et9wvdg', e.target, 'user_uE0bSPGbhRTmAF3I2fd3s')
    //   .then((result) => {
    //       console.log(result.text);
    //       Notiflix.Report.success(
    //         'Success',
    //         '"Thanks for sending a message, we\'ll be in touch soon."',
    //         'Okay',
    //         );
    //   }, (error) => {
    //       console.log(error.text);
    //       Notiflix.Report.failure(
    //         'An error occured',
    //         'Please try sending the message again.',
    //         'Okay',
    //         );
    //   });
  }

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        id="demo"
        className="flex justify-center items-center bg-white py-12"
      >
        <div className="mb-4 px-4 lg:px-20" data-aos="zoom-in">
          <div
            style={{ backgroundColor: "#34A8531A" }}
            className="banner w-full h-72 rounded-3xl flex justify-center items-center text-center"
          >
            <h2 className="sm:text-5xl md:text-6xl text-4xl font-semibold uppercase bg">
              Take our
              <span className="pl-4 font-fun text-primaryGreen">
                {activeTab === DEMO_PAGE ? "demo" : "exploration"}
              </span>
              <br></br>
              class today!
            </h2>
          </div>
          <div className="flex my-8 gap-8 md:flex-row flex-col">
            <div className="w-full bg-white  sm:px-8 sm:py-6 p-2 rounded-2xl border-2">
              <div className="flex justify-around border-b-2 border-gray-200">
                <button
                  className={`py-2 px-4 sm:text-2xl text-lg font-semibold hover:bg-gray-300 ${
                    activeTab === EXPLORATION_PAGE
                      ? "border-b-4 border-primaryGreen text-primaryGreen"
                      : "text-gray-500"
                  }`}
                  onClick={() => setActiveTab(EXPLORATION_PAGE)}
                >
                  Exploration Class
                </button>
                <div className="border-r-2 mb-2"></div>
                <button
                  className={`py-2 px-4 sm:text-2xl text-lg font-semibold hover:bg-gray-300  ${
                    activeTab === DEMO_PAGE
                      ? "border-b-4 border-primaryGreen text-primaryGreen"
                      : "text-gray-500"
                  }`}
                  onClick={() => setActiveTab(DEMO_PAGE)}
                >
                  Demo Class
                </button>
              </div>
              <div className="sm:p-4 p-1">
                <DemoForm activeTab={activeTab} />
              </div>
            </div>

            <div className="w-full md:w-5/12 flex flex-col py-6 md:py-4 justify-evenly border-2 rounded-2xl  px-4 gap-6">
              <h2 className="font-semibold text-lg text-center">
                {activeTab === DEMO_PAGE
                  ? "Why you should take the demo class ?"
                  : "Confused which technology to learn ? Take our exploration class"}
              </h2>
              {activeTab === DEMO_PAGE
                ? DEMO_CLASS_CARD_CONTENT.map((content) => {
                    const { title, description, bgColor, borderColor } =
                      content;
                    return (
                      <div
                        style={{
                          backgroundColor: bgColor,
                          borderColor: borderColor,
                        }}
                        className="p-4 gap-4 flex flex-col  rounded-2xl border-2"
                      >
                        <div className="flex flex-col gap-2">
                          <h4 className="font-semibold text-sm">{title}</h4>
                          <h6 className="text-xs">{description}</h6>
                        </div>
                      </div>
                    );
                  })
                : EXPLORATION_CLASS_CARD_CONTENT.map((content) => {
                    const { title, description, bgColor, borderColor } =
                      content;
                    return (
                      <div
                        style={{
                          backgroundColor: bgColor,
                          borderColor: borderColor,
                        }}
                        className={`p-4 gap-4 flex flex-col  rounded-2xl border-2`}
                      >
                        <div className="flex flex-col gap-2">
                          <h4 className="font-semibold text-sm">{title}</h4>
                          <h6 className="text-xs">{description}</h6>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DemoProduct;
