import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import BlackLogoSkillCraftersCo from "../images/BlackLogoSkillCraftersCo.png";
import { TRENDING_TECHNOLOGY_CONTENT } from "./content/CourseOverviewContent";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">
          {/* Top area: Blocks */}
          <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">
            {/* 1st block */}
            <div className="flex col-span-12 lg:col-span-4">
              {/* <div className="box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                            <h3 className="font-bold text-4xl mb-4">SkillsCraftersCo</h3>
                            <div className='text-md font-medium text-gray-600'>
                            </div>
    </div> */}
              <div className="flex flex-row justify-center items-center text-center font-semibold">
                <HashLink smooth to="/#hero">
                  <img
                    height={360}
                    width={360}
                    srcclassName="w-full object-contain"
                    src={BlackLogoSkillCraftersCo}
                  />
                </HashLink>
              </div>
            </div>

            {/* 2nd block */}
            <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
              <h6 className="text-primaryGreen text-xl font-bold mb-4">
                LINKS
              </h6>
              <ul className="text-md">
                <li className="mb-2">
                  <HashLink
                    to="/#aboutus"
                    className=" hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    About
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/courses"
                    className=" hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Courses
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/exploration-class"
                    className=" hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Explore
                  </HashLink>
                </li>
              </ul>
            </div>

            {/* 3rd block */}
            <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
              <h6 className="text-primaryGreen text-xl font-bold mb-4">
                OUR COURSES
              </h6>
              <ul className="text-md">
                {TRENDING_TECHNOLOGY_CONTENT.map((technology) => {
                  return (
                    <li className="mb-2">
                      <HashLink to={technology.buttonRouteLink} className="">
                        {technology.title}
                      </HashLink>
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* 4th block */}
            <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-primaryGreen">
              <div className="text-xl mb-6">Social Media Links.</div>

              <div className="text-md font-medium mb-6 text-black">
                Follow us on social media.
              </div>
              <div className="mx-auto text-center mt-2">
                <ul className="flex justify-center mb-4 md:mb-0">
                  <li>
                    {/* <Link
                        to="#"
                        className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                        aria-label="Twitter"
                      >
                        <svg
                          className="w-8 h-8 fill-current"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                        </svg>
                      </Link> */}
                  </li>
                  <li className="ml-4">
                    <button
                      onClick={() => {
                        window.open(
                          "https://www.instagram.com/skillcraftersco/?igsh=MTZxbDlxZG85b3BqYw%3D%3D"
                        );
                      }}
                      className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                      aria-label="Instagram"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="32"
                        height="32"
                        viewBox="0 0 64 64"
                      >
                        <radialGradient
                          id="TGwjmZMm2W~B4yrgup6jda_119026_gr1"
                          cx="32"
                          cy="32.5"
                          r="31.259"
                          gradientTransform="matrix(1 0 0 -1 0 64)"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="0" stop-color="#efdcb1"></stop>
                          <stop offset="0" stop-color="#f2e0bb"></stop>
                          <stop offset=".011" stop-color="#f2e0bc"></stop>
                          <stop offset=".362" stop-color="#f9edd2"></stop>
                          <stop offset=".699" stop-color="#fef4df"></stop>
                          <stop offset="1" stop-color="#fff7e4"></stop>
                        </radialGradient>
                        <path
                          fill="url(#TGwjmZMm2W~B4yrgup6jda_119026_gr1)"
                          d="M58,54c-1.1,0-2-0.9-2-2s0.9-2,2-2h2.5c1.9,0,3.5-1.6,3.5-3.5S62.4,43,60.5,43H50c-1.4,0-2.5-1.1-2.5-2.5	S48.6,38,50,38h8c1.7,0,3-1.3,3-3s-1.3-3-3-3H42v-6h18c2.3,0,4.2-2,4-4.4c-0.2-2.1-2.1-3.6-4.2-3.6H58c-1.1,0-2-0.9-2-2s0.9-2,2-2	h0.4c1.3,0,2.5-0.9,2.6-2.2c0.2-1.5-1-2.8-2.5-2.8h-14C43.7,9,43,8.3,43,7.5S43.7,6,44.5,6h3.9c1.3,0,2.5-0.9,2.6-2.2	C51.1,2.3,50,1,48.5,1H15.6c-1.3,0-2.5,0.9-2.6,2.2C12.9,4.7,14,6,15.5,6H19c1.1,0,2,0.9,2,2s-0.9,2-2,2H6.2c-2.1,0-4,1.5-4.2,3.6	C1.8,16,3.7,18,6,18h2.5c1.9,0,3.5,1.6,3.5,3.5S10.4,25,8.5,25H5.2c-2.1,0-4,1.5-4.2,3.6C0.8,31,2.7,33,5,33h17v11H6	c-1.7,0-3,1.3-3,3s1.3,3,3,3l0,0c1.1,0,2,0.9,2,2s-0.9,2-2,2H4.2c-2.1,0-4,1.5-4.2,3.6C-0.2,60,1.7,62,4,62h53.8	c2.1,0,4-1.5,4.2-3.6C62.2,56,60.3,54,58,54z"
                        ></path>
                        <radialGradient
                          id="TGwjmZMm2W~B4yrgup6jdb_119026_gr2"
                          cx="18.51"
                          cy="66.293"
                          r="69.648"
                          gradientTransform="matrix(.6435 -.7654 .5056 .4251 -26.92 52.282)"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset=".073" stop-color="#eacc7b"></stop>
                          <stop offset=".184" stop-color="#ecaa59"></stop>
                          <stop offset=".307" stop-color="#ef802e"></stop>
                          <stop offset=".358" stop-color="#ef6d3a"></stop>
                          <stop offset=".46" stop-color="#f04b50"></stop>
                          <stop offset=".516" stop-color="#f03e58"></stop>
                          <stop offset=".689" stop-color="#db359e"></stop>
                          <stop offset=".724" stop-color="#ce37a4"></stop>
                          <stop offset=".789" stop-color="#ac3cb4"></stop>
                          <stop offset=".877" stop-color="#7544cf"></stop>
                          <stop offset=".98" stop-color="#2b4ff2"></stop>
                        </radialGradient>
                        <path
                          fill="url(#TGwjmZMm2W~B4yrgup6jdb_119026_gr2)"
                          d="M45,57H19c-5.5,0-10-4.5-10-10V21c0-5.5,4.5-10,10-10h26c5.5,0,10,4.5,10,10v26C55,52.5,50.5,57,45,57z"
                        ></path>
                        <path
                          fill="#fff"
                          d="M32,20c4.6,0,5.1,0,6.9,0.1c1.7,0.1,2.6,0.4,3.2,0.6c0.8,0.3,1.4,0.7,2,1.3c0.6,0.6,1,1.2,1.3,2 c0.2,0.6,0.5,1.5,0.6,3.2C46,28.9,46,29.4,46,34s0,5.1-0.1,6.9c-0.1,1.7-0.4,2.6-0.6,3.2c-0.3,0.8-0.7,1.4-1.3,2 c-0.6,0.6-1.2,1-2,1.3c-0.6,0.2-1.5,0.5-3.2,0.6C37.1,48,36.6,48,32,48s-5.1,0-6.9-0.1c-1.7-0.1-2.6-0.4-3.2-0.6 c-0.8-0.3-1.4-0.7-2-1.3c-0.6-0.6-1-1.2-1.3-2c-0.2-0.6-0.5-1.5-0.6-3.2C18,39.1,18,38.6,18,34s0-5.1,0.1-6.9 c0.1-1.7,0.4-2.6,0.6-3.2c0.3-0.8,0.7-1.4,1.3-2c0.6-0.6,1.2-1,2-1.3c0.6-0.2,1.5-0.5,3.2-0.6C26.9,20,27.4,20,32,20 M32,17 c-4.6,0-5.2,0-7,0.1c-1.8,0.1-3,0.4-4.1,0.8c-1.1,0.4-2.1,1-3,2s-1.5,1.9-2,3c-0.4,1.1-0.7,2.3-0.8,4.1C15,28.8,15,29.4,15,34 s0,5.2,0.1,7c0.1,1.8,0.4,3,0.8,4.1c0.4,1.1,1,2.1,2,3c0.9,0.9,1.9,1.5,3,2c1.1,0.4,2.3,0.7,4.1,0.8c1.8,0.1,2.4,0.1,7,0.1 s5.2,0,7-0.1c1.8-0.1,3-0.4,4.1-0.8c1.1-0.4,2.1-1,3-2c0.9-0.9,1.5-1.9,2-3c0.4-1.1,0.7-2.3,0.8-4.1c0.1-1.8,0.1-2.4,0.1-7 s0-5.2-0.1-7c-0.1-1.8-0.4-3-0.8-4.1c-0.4-1.1-1-2.1-2-3s-1.9-1.5-3-2c-1.1-0.4-2.3-0.7-4.1-0.8C37.2,17,36.6,17,32,17L32,17z"
                        ></path>
                        <path
                          fill="#fff"
                          d="M32,25c-5,0-9,4-9,9s4,9,9,9s9-4,9-9S37,25,32,25z M32,40c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S35.3,40,32,40 z"
                        ></path>
                        <circle cx="41" cy="25" r="2" fill="#fff"></circle>
                      </svg>
                    </button>
                  </li>
                  <li className="ml-4">
                    <button
                      onClick={() => {
                        window.open(
                          "https://www.facebook.com/profile.php?id=61561179800228"
                        );
                      }}
                      className="flex justify-center items-center text-blue-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                      aria-label="Facebook"
                    >
                      <svg
                        className="w-8 h-8 fill-current"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                      </svg>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
              <div className="text-sm  font-semibold py-1">
                Copyright &copy; {new Date().getFullYear()}
                {"  "}
                <HashLink to="#" className=" hover:text-blue-900">
                  SkillsCraftersCo
                </HashLink>
                . All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
