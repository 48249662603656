import React from "react";
import { HashLink } from "react-router-hash-link";
import StylishButton from "../StylishButton";

const NavLinks = () => {
  return (
    <div className="flex flex-col md:flex-row items-center">
      <HashLink
        className="attention-grabbing-link px-4 py-2 font-bold"
        smooth
        to="/exploration-class"
      >
        Exploration class
      </HashLink>
      <HashLink
        className="px-4 py-2 font-bold text-black hover:text-primaryYellow"
        smooth
        to="/courses"
      >
        Courses
      </HashLink>
      <HashLink
        className="px-4 py-2 font-bold text-black hover:text-primaryYellow cursor-pointer"
        smooth
        to="/#aboutus"
      >
        About Us
      </HashLink>
      {/* <HashLink
        className="px-4 py-2 font-bold text-black hover:text-primaryYellow"
        smooth
        to="/contact"
      >
        Contact Us
      </HashLink> */}
      <HashLink
        className="inline-block items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-primaryGreen border-2 border-transparent rounded-xl shadow-sm hover:bg-transparent hover:text-primaryGreen hover:border-primaryGreen focus:outline-none"
        smooth
        to="/demo-class"
      >
        Book Free Demo
      </HashLink>
    </div>
  );
};

export default NavLinks;
