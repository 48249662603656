import React, { useEffect, useState } from "react";
import Stepper from "./Stepper";
import {
  DEMO_PAGE,
  DEMO_STEPS,
  EXPLORATION_STEPS,
} from "./content/DemoContent";
import emailjs from "emailjs-com";
import Notiflix from "notiflix";

const SELECT_DROPDOWN_OPTIONS = [
  {
    label: "Python Foundations: Mastering Essentials ( python - level 1)",
    value: "python1",
  },
  {
    label: "Exploration in Data science with python ( python - level 2)",
    value: "python2",
  },
  {
    label: "Mastery in Machine learning and AI  ( python - level 3)",
    value: "python3",
  },
  {
    label: "Web Foundations: Mastering Essentials ( web - level 1)",
    value: "web1",
  },
  {
    label: "Exploring Advanced Web With ReactJs ( web - level 2)",
    value: "web2",
  },
  {
    label: "Backend: Building Powerful Web apps ( web - level 3)",
    value: "web3",
  },
  {
    label: "Cyber Security: Protecting Ourselves",
    value: "cyber1",
  },
  {
    label: "Maths for School",
    value: "m1",
  },
];
const DemoForm = ({ activeTab }) => {
  const [demoFormData, setDemoFormData] = useState({
    parentName: "",
    parentEmailId: "",
    parentPhone: "",
    country: "",
    studentName: "",
    studentGrade: "",
    courseSelected: "",
    message: "",
  });

  const {
    parentName,
    parentEmailId,
    parentPhone,
    country,
    studentName,
    studentGrade,
    courseSelected,
    message,
  } = demoFormData;

  const [demoFormErrors, setDemoFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInput = (e) => {
    const newFormData = { ...demoFormData };
    newFormData[e.target.name] = e.target.value;
    if (demoFormErrors[e.target.name]) {
      const newErrors = { ...demoFormErrors };
      delete newErrors[e.target.name];
      setDemoFormErrors(newErrors);
    }
    setDemoFormData({ ...newFormData });
  };

  const clearErrors = () => {};
  useEffect(() => {
    setDemoFormErrors({});
    setDemoFormData({
      parentName: "",
      parentEmailId: "",
      parentPhone: "",
      country: "",
      studentName: "",
      studentGrade: "",
      courseSelected: "",
      message: "",
    });
  }, [activeTab]);

  const validite = () => {
    let newErrors = { ...demoFormErrors };
    Object.keys(demoFormData).forEach((key) => {
      if (
        demoFormData[key] === "" ||
        demoFormData[key] === null ||
        demoFormData[key] === undefined
      ) {
        if (
          key === "message" ||
          (key === "courseSelected" && activeTab !== DEMO_PAGE)
        ) {
        } else {
          newErrors[key] = true;
        }
      } else {
        if (newErrors[key]) {
          delete newErrors[key];
        }
      }
    });
    if (newErrors && Object.keys(newErrors)?.length > 0) {
      setDemoFormErrors(newErrors);
      return false;
    }
    return true;
  };

  // function delay(ms) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }

  const sendEmail = async () => {
    setLoading(true);
    const result = await emailjs.send(
      "service_a5gd66l",
      "template_56t2fz3",
      demoFormData,
      "0tweu2VoEe__VEZ4E"
    );

    const { status } = result;

    setLoading(false);
    setDemoFormData({
      parentName: "",
      parentEmailId: "",
      parentPhone: "",
      country: "",
      studentName: "",
      studentGrade: "",
      courseSelected: "",
      message: "",
    });
    const successtext =
      activeTab === DEMO_PAGE
        ? '"Thanks for booking a demo, we\'ll be in touch soon."'
        : '"Thanks for booking a exporation class, we\'ll be in touch soon."';
    if (status === 200) {
      Notiflix.Report.success("Success", successtext, "Okay");
    } else {
      Notiflix.Report.failure(
        "An error occured",
        "Please try booking a demo again or try after sometime.",
        "Okay"
      );
    }
  };

  const handleSubmit = () => {
    if (!validite()) {
      return;
    }

    sendEmail();
  };

  return (
    <div className="flex flex-col items-center ">
      <div className="flex flex-col items-center w-full">
        <h1 className="font-bold text-center w-full uppercase text-4xl mt-8">
          {activeTab === DEMO_PAGE ? "Take Free Demo" : "Explore with us"}
        </h1>
        <Stepper
          steps={activeTab === DEMO_PAGE ? DEMO_STEPS : EXPLORATION_STEPS}
        />
      </div>
      <div className="w-full flex flex-wrap  justify-center gap-4">
        <div className="w-full sm:w-[46%]">
          <input
            name="parentName"
            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Parent's full name*"
            value={parentName}
            onChange={handleInput}
            onKeyUp={clearErrors}
          />
          {demoFormErrors.parentName && (
            <p className="text-red-500 text-xs">
              Please enter parent's full name
            </p>
          )}
        </div>
        <div className="w-full sm:w-[46%]">
          <input
            name="parentEmailId"
            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="email"
            placeholder="Parent's email ID*"
            value={parentEmailId}
            onChange={handleInput}
            onKeyUp={clearErrors}
          />
          {demoFormErrors.parentEmailId && (
            <p className="text-red-500 text-xs">
              Please enter parent's email id
            </p>
          )}
        </div>
        <div className="w-full sm:w-[46%]">
          <input
            name="parentPhone"
            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="number"
            placeholder="Phone (Whatsapp)*"
            value={parentPhone}
            onChange={handleInput}
            onKeyUp={clearErrors}
          />
          {demoFormErrors.parentPhone && (
            <p className="text-red-500 text-xs">
              Please enter parent's phone number
            </p>
          )}
        </div>
        <div className="w-full sm:w-[46%]">
          <input
            name="studentName"
            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Student's Name*"
            value={studentName}
            onChange={handleInput}
            onKeyUp={clearErrors}
          />
          {demoFormErrors.studentName && (
            <p className="text-red-500 text-xs">Please enter student's name</p>
          )}
        </div>
        <div className="w-full sm:w-[46%]">
          <input
            name="studentGrade"
            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Student's Grade*"
            value={studentGrade}
            onChange={handleInput}
            onKeyUp={clearErrors}
          />
          {demoFormErrors.studentGrade && (
            <p className="text-red-500 text-xs">Please enter student's grade</p>
          )}
        </div>
        <div className="w-full sm:w-[46%]">
          <input
            name="country"
            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Country*"
            value={country}
            onChange={handleInput}
            onKeyUp={clearErrors}
          />
          {demoFormErrors.country && (
            <p className="text-red-500 text-xs">Please enter your country</p>
          )}
        </div>
        {activeTab === DEMO_PAGE ? (
          <div className="w-full sm:w-[46%]">
            <select
              name="courseSelected"
              className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              placeholder="Select a course"
              value={courseSelected}
              onChange={handleInput}
              onKeyUp={clearErrors}
            >
              <option value="" disabled selected hidden>
                Select a course
              </option>
              {SELECT_DROPDOWN_OPTIONS.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
            {demoFormErrors.courseSelected && (
              <p className="text-red-500 text-xs">Please select a course</p>
            )}
          </div>
        ) : null}
        <div className="w-full sm:w-[46%]">
          <input
            name="message"
            className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"
            placeholder={
              activeTab === DEMO_PAGE
                ? "Any other course (optional)"
                : "Interest in any technology"
            }
            value={message}
            onChange={handleInput}
            onKeyUp={clearErrors}
          />
        </div>
      </div>
      <div className="my-2 mt-8 w-1/2 lg:w-2/4 items-center">
        {loading ? (
          <div className="flex justify-center" role="status">
            <svg
              aria-hidden="true"
              class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          <button
            onClick={handleSubmit}
            className="uppercase inline-block items-center tracking-wide p-3 justify-center px-4 py-2 text-base w-full  font-medium leading-6 text-white whitespace-no-wrap bg-primaryGreen border-2 border-transparent rounded-xl shadow-sm hover:bg-transparent hover:text-primaryGreen hover:border-primaryGreen focus:outline-none"
          >
            {activeTab === DEMO_PAGE ? "Book Demo" : "Book Exploration"}
          </button>
        )}
      </div>
    </div>
  );
};

export default DemoForm;
