import benefitIcon1 from "../../images/benefit_icon_1.svg";
import benefitIcon2 from "../../images/benefit_icon_2.svg";
import benefitIcon3 from "../../images/benefit_icon_3.svg";

export const BENEFITS_CARD = [
  {
    bgColor: "#F2201E33",
    borderColor: "#F2201E",
    title: "Project-Centric Learning Approach",
    description: "Hands-on experience through creating real-world projects.",
    img: benefitIcon1,
  },
  {
    bgColor: "#1784FB33",
    borderColor: "#1784FB",
    title: "Mentorship from Tech Buddies",
    description: "Learn from industry level tech professionals.",
    img: benefitIcon2,
  },
  {
    bgColor: "#FFB80133",
    borderColor: "#FFB801",
    title: "Customized Curriculum for Kids",
    description:
      "Flexibility to adapt to individual learning needs and interests.",
    img: benefitIcon3,
  },
  {
    bgColor: "#34A85333",
    borderColor: "#34A853",
    title: "Continuous Progress Tracking",
    description:
      "Feedback loops to track development and address areas of improvement.",
    img: benefitIcon2,
  },
  {
    bgColor: "#FFB80133",
    borderColor: "#FFB801",
    title: "Any Time Doubt Support",
    description:
      "Get clarification and guidance whenever you encounter doubts or challenges.",
    img: benefitIcon1,
  },
  {
    bgColor: "#34A85333",
    borderColor: "#34A853",
    title: "100% Money Back Guarantee",
    description: "Full refund if you are not satisfied with our services.",
    img: benefitIcon3,
  },
  {
    bgColor: "#F2201E33",
    borderColor: "#F2201E",
    title: "Flexible Learning Options",
    description:
      "Choose between personalized one-on-one sessions or in a small group with 2-4 kids.",
    img: benefitIcon2,
  },
  {
    bgColor: "#1784FB33",
    borderColor: "#1784FB",
    title: "Future-Ready Skills Development",
    description: "Preparation for success in a technology-driven world.",
    img: benefitIcon3,
  },
];
