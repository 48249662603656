import React from 'react';
import { HashLink } from 'react-router-hash-link';


const JoinUs = () => {
    return (
      <div className="mt-8">
        <section>
          <div className="my-4 py-4">
            <h2 className="font-fun my-2 text-center text-4xl font-bold text-primaryGreen">
              Join Us
            </h2>

            <h2 className="my-2 text-center text-3xl font-bold">
              Join us on this{" "}
              <span className="font-fun text-primaryYellow text-4xl">fun</span>{" "}
              learning adventure
            </h2>

            <h2 className="text-sm text-center my-1">
              Discover exciting coding projects, games, and challenges tailored
              just for kids!
            </h2>
            <div className="flex justify-center mt-10">
              <HashLink
                className="inline-block items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-primaryGreen border-2 border-transparent rounded-xl shadow-sm hover:bg-transparent hover:text-primaryGreen hover:border-primaryGreen focus:outline-none"
                smooth
                to="/demo-class"
              >
                Try a Free Demo Class today
              </HashLink>
            </div>
          </div>

          {/* <div className="p-16" data-aos="fade-in" data-aos-delay="600">
            <div className="grid sm:grid-cols-3 lg:grid-cols-3">
              <div
                style={clientImage}
                className="overflow-hidden flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100 w-1/6"
              >
                <img src={kws} alt="client" />
              </div>

              <div
                style={clientImage}
                className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100"
              >
                <img src={protergia} alt="client" />
              </div>

              <div
                style={clientImage}
                className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100"
              >
                <img src={geps} alt="client" />
              </div>
            </div>
          </div> */}
        </section>
      </div>
    );
}

export default JoinUs;