import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { BENEFITS_CARD } from "./content/BenefitsContent";

const Benefits = () => {
  return (
    <>
      <div className="m-auto max-w-6xl p-2 px-8 md:px-2 h-5/6" id="benefits">
        <h6 className="text-4xl text-center  text-blue-900 font-fun font-bold text-primaryGreen">
          Benefits
        </h6>
        <h3 className="text-4xl text-center font-bold ">Why choose us?</h3>
        <div className="grid grid-cols-auto gap-3 md:grid-cols-4 mt-12 mb-6">
          {BENEFITS_CARD.map((card) => {
            const { title, borderColor, bgColor, description, img } = card;
            return (
              <div
                style={{
                  borderColor: borderColor,
                  background: bgColor,
                }}
                className={
                  "md:p-5 p-4  gap-4 flex flex-col h-58 w-68 rounded-2xl border-2"
                }
              >
                <img height="48px" width="48px" className="md:block hidden" src={img}></img>
                <div className="flex flex-col gap-2">
                  <h4 className="font-semibold text-lg lg:text-xl">{title}</h4>
                  <h6 className="lg:text-base text-sm" >{description}</h6>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Benefits;
