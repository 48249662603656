import React, { useRef } from "react";

import { Link } from "react-router-dom";
import { TRENDING_TECHNOLOGY_CONTENT } from "./content/CourseOverviewContent";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import arrowicon from "../images/arrowicon.svg";
import { HashLink } from "react-router-hash-link";

const CourseOverview = () => {
  const slider = useRef(null);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 4000,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 808,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      id="course-overview"
      style={{ backgroundColor: "#F7F7F8" }}
      className="py-12"
    >
      <section>
        <div className="my-4 pb-4">
          <h2 className="font-fun my-2 text-center text-4xl font-bold text-primaryGreen">
            Discover
          </h2>

          <h2 className="my-2 text-center text-3xl font-bold">
            Trending Technologies
          </h2>
        </div>

        <div className="sm:px-12 px-4">
          <Slider ref={slider} {...settings}>
            {TRENDING_TECHNOLOGY_CONTENT.map((content, index) => {
              const { title, imgUrl, description, buttonRouteLink } = content;
              return (
                <div className="bg-white text-gray-700  rounded-lg  p-2 group">
                  <div className="flex flex-col m-2 h-144 sm:h-128 justify-between">
                    <div className="flex flex-col text-sm">
                      <img
                        alt="card img"
                        className="h-40 rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                        src={imgUrl}
                      />
                      <h2 className="font-semibold my-4 text-2xl text-center">
                        {title}
                      </h2>
                      <ul className="flex-col flex gap-2 sm:h-60">
                        {description.map((desc) => {
                          return (
                            <li className="text-md font-medium gap-2">
                              <span>
                                <svg
                                  class="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="#ffba00"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                              <h4>{desc}</h4>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="flex justify-center my-4 px-14">
                      <HashLink
                        to={buttonRouteLink}
                        className="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-primaryGreen border-2 border-transparent rounded-xl shadow-sm hover:bg-transparent hover:text-primaryGreen hover:border-primaryGreen focus:outline-none"
                      >
                        Enroll Now
                        <svg
                          className="w-4 h-4 ml-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </HashLink>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="px-14 flex gap-4 justify-center md:justify-end sm:mt-10 mt-12">
          <img
            style={{ transform: "scaleX(-1)", cursor: "pointer" }}
            src={arrowicon}
            height="40px"
            width="40px"
            onClick={() => slider?.current?.slickPrev()}
          />
          <img
            height="40px"
            width="40px"
            style={{ cursor: "pointer" }}
            src={arrowicon}
            onClick={() => slider?.current?.slickNext()}
          />
        </div>
      </section>
    </div>
  );
};

export default CourseOverview;
