

import webdev from "../../images/webdev.svg";
import appdev from "../../images/appdev.svg";
import mlai from "../../images/mlai.svg";
import cybersecurity from "../../images/cybersecurity.svg";
import maths from "../../images/maths.svg";

export const TRENDING_TECHNOLOGY_CONTENT = [
  {
    title: "Web Development",
    imgUrl: webdev,
    description: [
      "Dive into HTML, CSS, JavaScript, React, SQL, and Node.js to become proficient in web development.",
      "Build real-world applications from scratch, gaining practical, project-based experience.",
      "Learn to create websites that look great and function perfectly on any device.",
      "Start your journey to mastering both front-end and back-end technologies, becoming a versatile full-stack developer.",
    ],
    buttonRouteLink: "/courses/#web",
  },
  {
    title: "App Development",
    imgUrl: appdev,
    description: [
      "Learn the essentials of mobile app development using React Native, from basic concepts to advanced techniques.",
      "Build real-world mobile applications, gaining practical experience with each project.",
      "Master the skills needed to create apps that work seamlessly on both iOS and Android devices using a single codebase.",
      "Start your journey to becoming a skilled mobile app developer with React Native.",
    ],
    buttonRouteLink: "/courses/#web",
  },
  {
    title: "Machine Learning and AI",
    imgUrl: mlai,
    description: [
      "Start with the essentials and advance to advanced libraries, covering all you need to master Python.",
      "Engage with hands-on projects designed to help you harness Python's power effectively.",
      "Explore cutting-edge fields like Machine Learning and Artificial Intelligence to build intelligent systems.",
      "Embark on your journey to becoming a skilled Python developer and a specialist in Machine Learning and AI with our course.",
    ],
    buttonRouteLink: "/courses/#python",
  },
  {
    title: "Cyber Security",
    imgUrl: cybersecurity,
    description: [
      "Learn the essentials of cybersecurity, from fundamental principles to advanced techniques.",
      "Engage with real-world scenarios and projects to gain practical, hands-on experience in protecting digital assets.",
      "Master the skills needed to analyse threats and implement effective prevention strategies.",
      "Start your journey to learn cybersecurity & become capable of defending against complex cyber threats.",
    ],
    buttonRouteLink: "/courses/#cyber",
  },
  {
    title: "Maths",
    imgUrl: maths,
    description: [
      "Cover fundamental to advanced mathematical concepts, ensuring a solid understanding of the subject.",
      "Engage with interactive study material, including quizzes, practice tests, and exam simulations.",
      "Access advanced resources to deepen your knowledge of complex topics and stay ahead of the curve",
      "Embrace continuous learning and skill development across all topics, empowering yourself to excel academically .",
    ],
    buttonRouteLink: "/courses/#maths",
  },
];
