import React, { useEffect, useState } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import axios from "axios";
// import emailjs from 'emailjs-com';
import Notiflix from "notiflix";
import CourseList from "../components/CourseList";
import {
  CYBER_SECURITY_COURSES,
  MATHS,
  PYTHON_COURSES,
  WEBDEV_COURSES,
} from "../components/content/CoursesContent";
import { useLocation } from "react-router-dom";

const Courses = () => {
  useDocTitle("SkillsCraftersCo - Send us a message");
  const location = useLocation();

  useEffect(() => {
    if (!location) return;
    const { hash } = location;
    if (hash) return;
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div className="p-4">
        <CourseList
          title={
            "Master Python: From Basics to Data Science, Machine Learning, and AI"
          }
          courseArray={PYTHON_COURSES}
          id={"python"}
        />
        <CourseList
          title={"Web Development: Explore Limitless Possibilities in Web"}
          courseArray={WEBDEV_COURSES}
          id={"web"}
        />
        <CourseList
          title={"Cyber Security: Securing the Virtual World"}
          courseArray={CYBER_SECURITY_COURSES}
          id={"cyber"}
        />
        <CourseList title={"Maths"} courseArray={MATHS} id={"maths"} />
      </div>
      <Footer />
    </>
  );
};

export default Courses;
