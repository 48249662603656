import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const CourseList = ({ courseArray, title, id }) => {
  return (
    <>
      <div className="my-4 py-4" id={id}>
        <h2 className="my-2 text-center text-2xl uppercase font-bold">
          {title}
        </h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-primaryBlue mb-8"></div>
        </div>

        <div className="sm:px-16 px-0">
          <div className="flex gap-10 justify-center flex-wrap">
            {courseArray.map((course) => (
              <div
                style={{ width: "340px" }}
                className="border-2 courseCard  overflow-hidden text-gray-700 hover:scale-105 rounded-xl p-3 min-h-max"
              >
                <div className="m-2 flex flex-col justify-between h-full">
                  <div>
                    <h4 className="font-medium text-primaryBlue mb-4 text-lg md:text-2xl text-left mb-4 h-12">
                      {course.title}
                    </h4>
                    <div className="borderLine border-b-2 mb-2 mt-6"></div>
                    <h2 className=" font-fun text-primaryGreen font-semibold text-xl mb-2 flex gap-2 items-center">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 32 32"
                          height={20}
                          width={20}
                        >
                          <g data-name="46-List">
                            <path
                              stroke=""
                              strokeWidth={2}
                              d="M25 0H7a7 7 0 0 0-7 7v18a7 7 0 0 0 7 7h18a7 7 0 0 0 7-7V7a7 7 0 0 0-7-7zm5 25a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V7a5 5 0 0 1 5-5h18a5 5 0 0 1 5 5z"
                            />
                            <rect
                              x="7"
                              y="7"
                              width="4"
                              height="4"
                              rx="1"
                              ry="1"
                            />
                            <rect
                              x="7"
                              y="14"
                              width="4"
                              height="4"
                              rx="1"
                              ry="1"
                            />
                            <rect
                              x="7"
                              y="21"
                              width="4"
                              height="4"
                              rx="1"
                              ry="1"
                            />
                            <path d="M13 8h12v2H13zM13 15h12v2H13zM13 22h12v2H13z" />
                          </g>
                        </svg>
                      </span>
                      Topics covered
                    </h2>
                    <ul className="flex flex-col gap-1.5 ">
                      {course.features.map((feature) => {
                        return (
                          <li>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 32 32"
                              width={16}
                              height={16}
                              style={{ fill: "#ffba00" }}
                              className="mt-1 mr-2"
                            >
                              <g data-name="45-Check">
                                <path
                                  stroke="#ffba00"
                                  strokeWidth={2}
                                  d="M30 15v10a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V7a5 5 0 0 1 5-5h10V0H7a7 7 0 0 0-7 7v18a7 7 0 0 0 7 7h18a7 7 0 0 0 7-7V15z"
                                />
                                <path
                                  stroke="#ffba00"
                                  strokeWidth={2}
                                  d="m7.71 13.29-1.42 1.42 7 7a1 1 0 0 0 1.41 0l16-16-1.41-1.42L14 19.59z"
                                />
                              </g>
                            </svg>
                            <h3 className="text-md font-normal">{feature}</h3>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div>
                    <div className="flex justify-center gap-4 items-center border-t-2 pt-2 buttonContainer">
                      <HashLink
                        target="blank"
                        to="/demo-class"
                        className="font-semibold text-white bg-primaryGreen inline-flex items-center justify-center w-full px-2 py-2 my-2 text-md shadow-xl rounded-xl"
                      >
                        {"Book Demo"}
                      </HashLink>
                      <h2 className="text-sm font-semibold">
                        Starts @{" "}
                        <span className="text-primaryBlue">$25/Class</span>
                      </h2>
                    </div>
                    <div>
                      {title !== "Maths" ? (
                        <button
                          onClick={() => {
                            window.open(course.link);
                          }}
                          className="font-semibold text-black bg-white border-primaryGreen border-2 inline-flex items-center justify-center w-full px-2 py-2 my-2 text-md shadow-xl rounded-xl"
                        >
                          {"View Curriculum"}
                          <svg
                            className="w-4 h-4 ml-1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseList;
