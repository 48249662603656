import React, { useState } from "react";

const AccordionItem = ({
  title,
  content,
  index,
  activeIndex,
  setActiveIndex,
}) => {
  const isActive = activeIndex === index;

  const handleToggle = () => {
    setActiveIndex(isActive ? null : index);
  };

  return (
    <div className="px-1 md:px-0">
      <h2 id={`accordion-heading-${index}`}>
        <button
          type="button"
          className={`flex items-center justify-between w-full py-5 font-medium text-gray-500 border-b border-gray-200 gap-3`}
          onClick={handleToggle}
          aria-expanded={isActive}
          aria-controls={`accordion-body-${index}`}
        >
          <span className="text-left">{title}</span>
          <svg
            data-accordion-icon
            className={`w-3 h-3 ${
              !isActive ? "rotate-180" : ""
            } shrink-0 transition-transform`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id={`accordion-body-${index}`}
        className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
          isActive ? "max-h-screen" : "max-h-0"
        }`}
        aria-labelledby={`accordion-heading-${index}`}
      >
        <div className="py-5 border-b border-gray-200">{content}</div>
      </div>
    </div>
  );
};

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const accordionData = [
    {
      title: "Why Exploration class is necessary?",
      content: (
        <p className="mb-2 text-gray-500 dark:text-gray-400">
          Exploration class is essential for helping students discover their
          interests and strengths, and for providing guidance on future career
          paths and industry trends. It offer practical experience and insights
          into various fields, aiding informed decision-making for course and
          career choices.
        </p>
      ),
    },
    {
      title: "What sets you apart from other educational  platforms?",
      content: (
        <p className="mb-2 text-gray-500 dark:text-gray-400">
          We differentiate ourselves through fully customizable learning
          experiences tailored to each child's pace. Our platform offers
          project-based learning, future-proof skills, guidance from trained
          mentors, and unlimited doubt-solving, ensuring a personalized and
          effective educational journey.
        </p>
      ),
    },
    {
      title: "Why is coding necessary for kids?",
      content: (
        <p className="mb-2 text-gray-500 dark:text-gray-400">
          Coding is essential for kids as it enhances problem-solving skills,
          logical thinking, and creativity. It also prepares them for future
          career opportunities in a tech-driven world and fosters resilience and
          perseverance through challenging projects.
        </p>
      ),
    },
    {
      title: "Is any previous coding experience required?",
      content: (
        <p className="mb-2 text-gray-500 dark:text-gray-400">
          No previous coding experience is required. Our courses are designed to
          cater to all skill levels, from complete beginners to advanced
          learners, ensuring a smooth and supportive learning journey for every
          student.
        </p>
      ),
    },
    {
      title: "How is teaching quality maintained?",
      content: (
        <p className="mb-2 text-gray-500 dark:text-gray-400">
          Teaching quality is maintained through rigorous instructor selection,
          continuous training, and regular evaluations. Our educators are
          experts in their fields, ensuring engaging and effective learning
          experiences. Additionally, we gather feedback from students and
          parents to continuously improve our teaching methods and content.
        </p>
      ),
    },
  ];

  return (
    <div id="accordion-flush" data-accordion="collapse">
      {accordionData.map((item, index) => (
        <AccordionItem
          key={index}
          index={index}
          title={item.title}
          content={item.content}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      ))}
    </div>
  );
};

export default Accordion;
