import job from "../../images/job.png"
import webinar from "../../images/webinar.png";
import workSchedule from "../../images/work-schedule.png";

export const DEMO_PAGE = "demo-class";
export const EXPLORATION_PAGE = "exploration-class";
export const DEMO_CLASS_CARD_CONTENT = [
  {
    title: "Hands-On Experience",
    description: "Get a feel for the interactive and engaging teaching style.",
    img: "",
    bgColor: "#F2201E33",
    borderColor: "#F2201E",
  },
  {
    title: "Curriculum Overview",
    description:
      "Understand the course structure and content that will be covered.",
    img: "",
    bgColor: "#1784FB33",
    borderColor: "#1784FB",
  },
  {
    bgColor: "#FFB80133",
    borderColor: "#FFB801",
    title: "Meet the Instructor",
    description:
      "Interact with the instructor to gauge their expertise and teaching methods.",
    img: "",
  },
  {
    bgColor: "#34A85333",
    borderColor: "#34A853",
    title: "Interactive Learning Environment",
    description:
      "Experience the dynamic and supportive learning environment first-hand.",
    img: "",
  },
  {
    bgColor: "#F2201E33",
    borderColor: "#F2201E",
    title: "Q&A Session",
    description:
      "Get all your questions answered about the course, schedule, and outcomes.",
    img: "",
  },
];

export const EXPLORATION_CLASS_CARD_CONTENT = [
  {
    title: "Technology Overview",
    description:
      "Gain insights into various technologies and their applications.",
    img: "",
    bgColor: "#F2201E33",
    borderColor: "#F2201E",
  },
  {
    title: "Informed Decision Making",
    description:
      "Understand different coding paths to choose the one that excites you the most.",
    img: "",
    bgColor: "#1784FB33",
    borderColor: "#1784FB",
  },
  {
    title: "Hands-On Demos",
    description:
      "Participate in hands-on demonstrations of multiple technologies.",
    img: "",
    bgColor: "#FFB80133",
    borderColor: "#FFB801",
  },
  {
    title: "Interest Identification",
    description:
      "Discover your interests and strengths in different areas of technology.",
    img: "",
    bgColor: "#34A85333",
    borderColor: "#34A853",
  },
  {
    title: "Interactive Q&A",
    description:
      "Engage in a Q&A session to clarify doubts and gather more information about each technology.",
    img: "",
    bgColor: "#F2201E33",
    borderColor: "#F2201E",
  },
];


export const DEMO_STEPS = [
  {
    title: "Apply for Demo class",
    imgSrc: job,
    alt: "Step 1 Image",
  },
  {
    title: "Schedule a meet",
    imgSrc: workSchedule,
    alt: "Step 2 Image",
  },
  {
    title: "Take the Demo class",
    imgSrc: webinar,
    alt: "Step 3 Image",
  },
];

export const EXPLORATION_STEPS = [
  {
    title: "Apply for Exploration Class",
    imgSrc: job,
    alt: "Step 1 Image",
  },
  {
    title: "Schedule a Meet",
    imgSrc: workSchedule,
    alt: "Step 2 Image",
  },
  {
    title: "Take the  Exploration Class",
    imgSrc: webinar,
    alt: "Step 3 Image",
  },
];